<template>
  <div
    v-if="currentSeason && currentRound"
    class="rounds-container"
    @keyup.left="previewsCard"
    @keyup.right="nextCard"
  >
    <v-card align="center" class="showed-card" elevation="10" max-width="500">
      <v-card-title>
        <v-row class="text-h4" justify="center">
          Season {{ currentSeason.name }}
        </v-row>
      </v-card-title>
      <div class="dates pt-4">
        <span class="font-weight-bold mb-4 d-inline-block"
          >Current round {{ currentRound.name }}</span
        >
        <v-autocomplete
          :items="sortedRounds"
          filled
          hide-details
          label="Next Round"
          return-object
          item-text="name"
          v-model="nextRound"
        ></v-autocomplete>
        <v-btn
          class="green mt-8"
          min-width="200"
          elevation="2"
          @click.stop="saveSeason"
          >SAVE
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { POST_RESOURCE_PATH } from "../../../common/apiRequests";
import { patchResource } from "../../../utils/resourceRequests";

export default {
  name: "Seasons",
  data() {
    return {
      currentRound: null,
      nextRound: null,
    };
  },
  methods: {
    async saveSeason() {
      const current_round = this.nextRound?.id;
      const next_round = this.rounds.find(
        (r) => +r.name === +this.nextRound.name + 1
      )?.id;

      const payload = {
        ...this.currentSeason,
        current_round,
        next_round,
      };

      await patchResource({
        resourcePath: POST_RESOURCE_PATH.SEASON_CREATE,
        mainId: this.currentSeason.id,
        payload,
      });
      await this.$store.dispatch("seasons/fetchSeasons");
    },
  },
  computed: {
    ...mapState("seasons", ["seasons"]),
    ...mapState("rounds", ["rounds"]),
    currentSeason() {
      if (!this.seasons?.length) {
        return null;
      }
      return this.seasons[this.seasons.length - 1];
    },
    sortedRounds() {
      if (!this.rounds?.length) {
        return [];
      }
      return [...this.rounds]
        .filter((r) => +r.name > +this.currentRound.name)
        .sort((a, b) => +a.name - +b.name);
    },
  },
  watch: {
    rounds(nv) {
      const current = nv.find(
        (r) => r.name === this.currentSeason.current_round
      );

      this.currentRound = current;
      this.nextRound = current;
    },
  },
  async created() {
    await this.$store.dispatch("seasons/fetchSeasons");
    await this.$store.dispatch("rounds/fetchRounds");
  },
};
</script>

<style lang="scss">
$btn-color: #5ac683;

.row.dates {
  margin-top: 2rem;
}

.h2h-icon {
  margin: -0.3rem 0 0 0.5rem;
}

.rounds-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .showed-card {
    margin-top: 20px;
    padding: 2rem;
  }

  .row.buttons,
  .rounds-pagination {
    margin-top: 1rem;

    .v-btn {
      margin: 0 0.5rem;
    }
  }
}
</style>
